'use client';

import useChanged from '@/hooks/use-changed';
import { fetchCurrentWeek, fetchWeeks } from '@/lib/helpers';
import {
  isMySermonsSearchType,
  isSearchSubType,
  isSearchType,
  MY_SERMONS_SEARCH_TYPE,
  SEARCH_SUB_TYPE,
  SEARCH_TYPE,
  Suggestion,
} from '@/types/sermons';
import { usePathname, useRouter } from 'next/navigation';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import Button from '../molecules/button';
import SearchInput from '../molecules/search-input';
import SearchLectionaryDropdown from '../molecules/search-lectionary-dropdown';
import SearchScriptureDropdown from '../molecules/search-scripture-dropdown';
import SearchSubTypeDropdown from '../molecules/search-sub-type-dropdown';
import SearchTypeDropdown from '../molecules/search-type-dropdown';
import { useAppState } from '../providers/state-provider';

const getSearchSubTypes = (searchType: string): SEARCH_SUB_TYPE[] => {
  switch (searchType) {
    case 'Dramas':
    case 'Eulogies':
      return ['Keyword', 'Scripture', 'Year A', 'Year B', 'Year C', 'Show All'];
    case 'Bulletin & Sermon Aids':
      return ['Scripture', 'Year A', 'Year B', 'Year C'];
    case 'Dictionary':
    case 'Bible':
      return ['Keyword', 'Scripture'];
    case 'Commentary':
      return ['Scripture'];
    case 'Lectionary':
    case "Children's Bulletins":
    case 'Sermon Series':
    case 'King Duncan':
    case 'Authors A-Z':
      return ['N/A'];
    default:
      return ['Keyword', 'Scripture', 'Year A', 'Year B', 'Year C'];
  }
};

export default function SearchBar() {
  const router = useRouter();
  const pathname = usePathname();

  const {
    state: {
      mySermons,
      bibleBooks,
      lectionaryWeeks,
      currentWeek,
      searchParams,
      searchType,
      searchSubType,
      searchTerm: term,
      searchOldTestamentBook: oldTestamentBook,
      searchNewTestamentBook: newTestamentBook,
      searchChapter: chapter,
      searchPericope: pericope,
      user,
      token,
    },
    dispatch,
  } = useAppState();

  const { isMySermons } = mySermons;

  const setSearchType = useCallback(
    (payload: SEARCH_TYPE | MY_SERMONS_SEARCH_TYPE) =>
      dispatch({ type: 'searchType', payload }),
    [dispatch],
  );
  const setSearchSubType = useCallback(
    (payload: SEARCH_SUB_TYPE) => dispatch({ type: 'searchSubType', payload }),
    [dispatch],
  );
  const setTerm = useCallback(
    (payload: string) => dispatch({ type: 'searchTerm', payload }),
    [dispatch],
  );
  const setOldTestamentBook = useCallback(
    (payload: number | '') =>
      dispatch({ type: 'searchOldTestamentBook', payload }),
    [dispatch],
  );
  const setNewTestamentBook = useCallback(
    (payload: number | '') =>
      dispatch({ type: 'searchNewTestamentBook', payload }),
    [dispatch],
  );
  const setChapter = useCallback(
    (payload: number | '') => dispatch({ type: 'searchChapter', payload }),
    [dispatch],
  );
  const setPericope = useCallback(
    (payload: string) => dispatch({ type: 'searchPericope', payload }),
    [dispatch],
  );

  const [searchTypeOpen, setSearchTypeOpen] = useState<boolean>(false);
  const [searchSubTypeOpen, setSearchSubTypeOpen] = useState<boolean>(false);
  const [suggestionTerm, setSuggestionTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const [selectedSuggestion, setSelectedSuggestion] =
    useState<Suggestion | null>(null);
  const [selectedWeek, setSelectedWeek] = useState<string | undefined>(
    currentWeek?.number,
  );
  const [chapters, setChapters] = useState<number[]>([]);
  const [verseStrings, setVerseStrings] = useState<
    { abbrev: string; code: string }[]
  >([]);

  const searchSubTypes = getSearchSubTypes(searchType);

  const currentWeekChanged = useChanged(currentWeek);

  useEffect(() => {
    if (currentWeek && currentWeekChanged) {
      setSelectedWeek(currentWeek.number);
    }
  }, [currentWeek, currentWeekChanged]);

  useEffect(() => {
    if (
      (user?.lectionaryCalendarFavorite &&
        user.lectionaryCalendarFavorite !== 'R' &&
        currentWeek?.denomination !== user.lectionaryCalendarFavorite) ||
      !user?.lectionaryCalendarFavorite ||
      currentWeek?.denomination !== user.lectionaryCalendarFavorite
    ) {
      (async () => {
        const currentWeek = await fetchCurrentWeek(undefined, token);
        dispatch({ type: 'currentWeek', payload: currentWeek });
      })().catch(console.error);
    }
  }, [
    currentWeek?.denomination,
    dispatch,
    token,
    user?.lectionaryCalendarFavorite,
  ]);

  useEffect(() => {
    if (
      (user?.lectionaryCalendarFavorite &&
        user.lectionaryCalendarFavorite !== 'R' &&
        lectionaryWeeks?.a[0].denomination !==
          user.lectionaryCalendarFavorite) ||
      (!user?.lectionaryCalendarFavorite &&
        lectionaryWeeks?.a[0].denomination !== 'R')
    ) {
      (async () => {
        const lectionaryWeeks = await fetchWeeks(token, false);
        dispatch({ type: 'lectionaryWeeks', payload: lectionaryWeeks });
      })().catch(console.error);
    }
  }, [dispatch, lectionaryWeeks?.a, token, user?.lectionaryCalendarFavorite]);

  useEffect(() => {
    if (!suggestions.length) {
      setSuggestionTerm(term);
    }
  }, [suggestions, term]);

  useEffect(() => {
    if (!searchSubTypes.includes(searchSubType)) {
      setSearchSubType(searchSubTypes[0]);
    }
  }, [searchSubType, searchSubTypes]);

  const termParam = searchParams.get('term') || '';

  const [cancelNext, setCancelNext] = useState(true);

  useEffect(() => {
    const { pathname } = window.location;
    const pericopeParam = searchParams.get('pericope') || '';
    const chapterParam = searchParams.get('chapter') || '';
    const bookParam = searchParams.get('book') || '';
    const typeParam = searchParams.get('type') || '';
    const weekParam = searchParams.get('week') || '';
    const yearParam = searchParams.get('year') || '';

    if (pathname === '/dictionary') {
      setSearchType('Dictionary');
      setSearchSubType('N/A');
    } else if (pathname === '/lectionary') {
      setSearchType('Lectionary');
      setSearchSubType('N/A');
    } else if (pathname.startsWith('/bible')) {
      setSearchType('Bible');
      setSearchSubType('Keyword');
      setTerm(termParam);
    } else if (pathname === '/childrens-bulletins') {
      setSearchType("Children's Bulletins");
      setSearchSubType('N/A');
    } else if (pathname === '/sermon-series') {
      setSearchType('Sermon Series');
      setSearchSubType('N/A');
    } else if (pathname === '/king-duncan') {
      setSearchType('King Duncan');
      setSearchSubType('N/A');
    } else if (pathname === '/author-list') {
      setSearchType('Authors A-Z');
      setTerm(termParam);
    } else if (pathname.startsWith('/search/')) {
      setSearchType(
        isMySermons
          ? isMySermonsSearchType(typeParam)
            ? typeParam
            : 'Sermons'
          : isSearchType(typeParam)
            ? typeParam
            : 'Sermon Prep',
      );
      if (pathname.startsWith('/search/keyword')) {
        if (searchParams.get('showAll') !== null) {
          setSearchSubType('Show All');
        } else {
          setSearchSubType('Keyword');
        }
      } else if (pathname.startsWith('/search/scripture')) {
        setSearchSubType('Scripture');
      } else if (pathname.startsWith('/search/lectionary')) {
        const year = `Year ${yearParam}`;
        const currentYear = `Year ${currentWeek?.cycle}`;
        setSearchSubType(
          isSearchSubType(year)
            ? year
            : isSearchSubType(currentYear)
              ? currentYear
              : 'Year A',
        );
      }
      if (term !== termParam) {
        setCancelNext(true);
        setTerm(termParam);
      }
      setPericope(pericopeParam);
      setChapter(+chapterParam);
      const newBook = +bookParam;
      if (newBook >= 40) {
        setNewTestamentBook(newBook);
      } else {
        setOldTestamentBook(newBook);
      }

      setSelectedWeek(weekParam);
    } else if (pathname === '/my-sermons') {
      setSearchType('Create');
    } else if (pathname.startsWith('/my-sermons/')) {
      setSearchType('Sermons');
    }
  }, [searchParams]);

  useEffect(() => {
    if (
      !isMySermons &&
      isMySermonsSearchType(searchType) &&
      !isSearchType(searchType)
    ) {
      setSearchType('Sermon Prep');
    } else if (
      isMySermons &&
      !isMySermonsSearchType(searchType) &&
      isSearchType(searchType)
    ) {
      setSearchType('Sermons');
    }
  }, [isMySermons, searchType, setSearchType]);

  const handleSearch = (termOverride?: string) => {
    const searchTerm = termOverride || term;
    setSuggestions([]);
    const url = new URL('https://null');

    if (searchSubType === 'Keyword' || searchSubType === 'Show All') {
      if (searchType === 'Bible') {
        url.pathname = '/bible/keyword';
        url.searchParams.set('term', searchTerm);
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      } else if (searchType === 'Dictionary') {
        url.pathname = '/dictionary/keyword';
        url.searchParams.set('term', searchTerm);
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      } else if (searchType === 'Authors A-Z') {
        if (!termOverride && !term) {
          url.pathname = '/author-list';
        } else {
          url.pathname = '/search/keyword';
          url.searchParams.set('term', searchTerm);
          url.searchParams.set('type', 'all');
          if (isMySermons) {
            url.searchParams.set('isMySermons', '');
          }
        }
      } else {
        url.pathname = '/search/keyword';
        url.searchParams.set('type', searchType);
        if (searchSubType === 'Show All') {
          url.searchParams.set('showAll', '');
        } else {
          url.searchParams.set('term', searchTerm);
        }
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      }
    } else if (searchSubType === 'Scripture') {
      const book = oldTestamentBook || newTestamentBook;
      if (searchType === 'Bible') {
        url.pathname = '/bible';
        url.searchParams.set('pericope', pericope);
        url.searchParams.set('chapter', `${chapter}`);
        url.searchParams.set('book', `${book}`);
        url.searchParams.set('typeOne', searchType);
        url.searchParams.set('typeTwo', searchType);
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      } else if (searchType === 'Dictionary') {
        url.pathname = '/dictionary/scripture';
        url.searchParams.set('pericope', pericope);
        url.searchParams.set('chapter', `${chapter}`);
        url.searchParams.set('book', `${book}`);
        url.searchParams.set('type', searchType);
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      } else {
        url.pathname = '/search/scripture';
        url.searchParams.set('pericope', pericope);
        url.searchParams.set('chapter', `${chapter}`);
        url.searchParams.set('book', `${book}`);
        url.searchParams.set('type', searchType);
        if (isMySermons) {
          url.searchParams.set('isMySermons', '');
        }
      }
    } else if (['Year A', 'Year B', 'Year C'].includes(searchSubType)) {
      const year = searchSubType.slice(-1);
      url.pathname = '/search/lectionary';
      url.searchParams.set('year', year);
      if (selectedWeek) {
        url.searchParams.set('week', selectedWeek);
      }
      url.searchParams.set('type', searchType);
      if (isMySermons) {
        url.searchParams.set('isMySermons', '');
      }
    } else if (searchSubType === 'N/A') {
      if (searchType === 'Lectionary') {
        url.pathname = '/lectionary';
      }
      if (searchType === "Children's Bulletins") {
        url.pathname = '/childrens-bulletins';
      }
      if (searchType === 'Sermon Series') {
        url.pathname = '/sermon-series';
        const categoryId = isNaN(+searchTerm) ? null : +searchTerm;
        if (categoryId !== null) {
          url.searchParams.set('category', `${categoryId}`);
        }
      }
      if (searchType === 'King Duncan') {
        url.pathname = '/king-duncan';
      } else if (searchType === 'Authors A-Z') {
        url.pathname = '/author-list';
      }
    }
    router.push(`${url.pathname}${url.search}`);
  };

  const [queueSearch, setQueueSearch] = useState(false);

  useEffect(() => {
    if (queueSearch) {
      handleSearch();
      setQueueSearch(false);
    }
  }, [queueSearch]);

  const handleSuggestionClick = (suggestion: Suggestion) => {
    setTerm(suggestion.term);
    setSuggestionTerm(suggestion.term);
    setSelectedSuggestion(suggestion);
    setSuggestions([]);
    setQueueSearch(true);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    } else if (
      (e.key === 'ArrowDown' || e.key === 'ArrowUp') &&
      suggestions.length
    ) {
      e.preventDefault();
      const currentIndex = suggestions.indexOf(
        selectedSuggestion as Suggestion,
      );
      let newIndex = currentIndex;
      if (e.key === 'ArrowDown') {
        newIndex = (currentIndex + 1) % suggestions.length;
      } else if (e.key === 'ArrowUp') {
        newIndex = (currentIndex - 1 + suggestions.length) % suggestions.length;
      }
      setSelectedSuggestion(suggestions[newIndex]);
      if (!suggestionTerm) {
        setSuggestionTerm(term);
      }
      setTerm(suggestions[newIndex].term);
    }
  };

  useEffect(() => {
    const handler = (e: MouseEvent) => {
      if (
        !document
          .querySelector('.search-type-button')
          ?.contains(e.target as Node)
      ) {
        setSearchTypeOpen(false);
      }
      if (
        !document
          .querySelector('.search-subtype-button')
          ?.contains(e.target as Node)
      ) {
        setSearchSubTypeOpen(false);
      }
      if (
        !document.querySelector('.search-input')?.contains(e.target as Node)
      ) {
        setSuggestions([]);
      }
    };
    document.addEventListener('click', handler);

    return () => {
      document.removeEventListener('click', handler);
    };
  }, []);

  if (
    pathname === '/admin' ||
    pathname.startsWith('/admin/') ||
    pathname.startsWith('/user/') ||
    pathname.endsWith('/print')
  ) {
    return null;
  }

  return (
    <section className='mb-2'>
      <form
        className='grid grid-cols-1 gap-2 lg:grid-cols-12'
        onSubmit={(e) => {
          e.preventDefault();
          handleSearch();
        }}
      >
        <div className='relative lg:col-span-3'>
          <SearchTypeDropdown
            searchType={searchType}
            setSearchType={setSearchType}
            searchTypeOpen={searchTypeOpen}
            setSearchTypeOpen={setSearchTypeOpen}
          />
        </div>
        <div className='grid grid-cols-1 gap-2 lg:col-span-9 lg:grid-cols-12'>
          <div className='relative lg:col-span-2'>
            <SearchSubTypeDropdown
              searchType={searchType}
              searchSubType={searchSubType}
              setSearchSubType={setSearchSubType}
              searchSubTypes={searchSubTypes}
              searchSubTypeOpen={searchSubTypeOpen}
              setSearchSubTypeOpen={setSearchSubTypeOpen}
              isMySermons={isMySermons}
              weekCycle={currentWeek?.cycle || 'A'}
            />
          </div>
          <div className='relative lg:col-span-8'>
            {searchSubType === 'Scripture' && (
              <SearchScriptureDropdown
                bibleBooks={bibleBooks}
                oldTestamentBook={oldTestamentBook}
                setOldTestamentBook={setOldTestamentBook}
                newTestamentBook={newTestamentBook}
                setNewTestamentBook={setNewTestamentBook}
                chapters={chapters}
                setChapters={setChapters}
                chapter={chapter}
                setChapter={setChapter}
                verseStrings={verseStrings}
                setVerseStrings={setVerseStrings}
                pericope={pericope}
                setPericope={setPericope}
                isMySermons={isMySermons}
              />
            )}
            {['Year A', 'Year B', 'Year C'].includes(searchSubType) && (
              <SearchLectionaryDropdown
                searchSubType={searchSubType}
                lectionaryWeeks={lectionaryWeeks}
                selectedWeek={selectedWeek}
                setSelectedWeek={setSelectedWeek}
                week={currentWeek}
              />
            )}
            {['Keyword', 'Show All', 'N/A'].includes(searchSubType) && (
              <SearchInput
                term={term}
                setTerm={setTerm}
                suggestionTerm={suggestionTerm}
                setSuggestionTerm={setSuggestionTerm}
                suggestions={suggestions}
                setSuggestions={setSuggestions}
                selectedSuggestion={selectedSuggestion}
                handleSuggestionClick={handleSuggestionClick}
                onKeyDown={handleKeyDown}
                searchSubType={searchSubType}
                isMySermons={isMySermons}
                cancelNext={cancelNext}
                setCancelNext={setCancelNext}
              />
            )}
          </div>
          <div className='lg:col-span-2'>
            <Button
              buttonType={isMySermons ? 'red' : 'default'}
              type='submit'
              className='flex h-8 w-full items-center justify-center'
              disabled={
                (searchSubType === 'Scripture' &&
                  !(oldTestamentBook || newTestamentBook)) ||
                (searchSubType === 'Year A' &&
                  !lectionaryWeeks?.a.find((w) => w.number === selectedWeek)) ||
                (searchSubType === 'Year B' &&
                  !lectionaryWeeks?.b.find((w) => w.number === selectedWeek)) ||
                (searchSubType === 'Year C' &&
                  !lectionaryWeeks?.c.find((w) => w.number === selectedWeek))
              }
            >
              Search
            </Button>
          </div>
        </div>
      </form>
    </section>
  );
}
